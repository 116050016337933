import { User } from '@/services/userService/userTypes';
import * as Sentry from '@sentry/nextjs';
import { AxiosError } from 'axios';
import {
  ConfirmCardSetupData,
  ConfirmCardSetupOptions,
  Stripe,
  StripeElements,
} from '@stripe/stripe-js/types';
import { SWRResponse } from 'swr';
import { HttpService } from '@/core/http/httpService';
import { serviceSingletonGetter } from '@/services/serviceGetter';
import { Interval } from '../priceService';
import {
  OneTimePayments,
  PaymentStrategies,
  RecurringPayments,
} from '../settingsService';
import formatRecurringInterval from './formatRecurringInterval';
import { STRIPE_ACCOUNT_ID } from './paymentConstants';
import {
  Intents,
  PaymentIntents,
  PaymentPlatform,
  PaymentProvider,
  PaymentStrategiesIds,
  PaypalCharge,
  PortalSession,
  SubscriptionInfo,
  Subscriptions,
  UpgradePreviewInfo,
} from './paymentTypes';
import { useSubscriptionManager } from './useSubscriptionManager';

export type ConfirmCardSetupCallback = (
  clientSecret: string,
  paymentConfig: ConfirmCardSetupData,
  handleActions: ConfirmCardSetupOptions
) => Promise<{
  paymentMethodId: string;
}>;

export type PaymentService = {
  createPortalSession: (
    cancellationCode: string,
    customerId: string
  ) => Promise<PortalSession>;
  getStripeClientIntentsData: () => Promise<Intents>;
  createStripePaymentIntents: ({
    priceId,
    taskId,
    exports,
  }: {
    priceId: string;
    taskId?: string;
    exports?: number;
  }) => Promise<PaymentIntents>;
  confirmStripePaymentIntent: ({
    paymentIntentId,
  }: {
    paymentIntentId: string;
  }) => Promise<PaymentIntents>;
  createStripeSubscription: (
    paymentMethodId: string,
    priceId: string,
    isFreeTrialEnabled: boolean,
    withIntroPrice: boolean,
    unlockedExports?: number | null
  ) => Promise<Subscriptions>;
  createPaypalSubscription: (
    subscriptionId: string,
    unlockedExports?: number | null
  ) => Promise<any>;
  createPaypalCharge: (charge: PaypalCharge) => Promise<any>;
  getSubscription: (subscriptionProvider: string) => Promise<SubscriptionInfo>;
  previewSubscriptionUpgrade: (
    subscriptionProvider: string,
    newPriceId?: string
  ) => Promise<UpgradePreviewInfo>;
  upgradeStripeSubscription: (newPriceId: string) => Promise<void>;
  upgradePaypalSubscription: (newSubscriptionId: string) => Promise<void>;
  cancelSubscription: (subscriptionProvider: string) => Promise<any>;
  cancelSubscriptionFromEmail: (
    subscriptionProvider: string,
    cancellationCode: string
  ) => Promise<any>;
  isPaypal: (providerRef: string) => boolean;
  trackPayment: (event: {
    track: (
      category: string,
      action: string,
      additionalParams?: Record<string, any> | undefined,
      label?: string | undefined
    ) => void;
    status?: string;
    paymentPlatform?: PaymentPlatform;
    priceId?: string;
    freeTrialEnabled?: boolean;
    emailReminderEnabled?: boolean;
    paymentStrategy: 'subscription' | 'oneTime';
  }) => void;
  catchStripePriceError: (
    stripe?: Stripe | null,
    elements?: StripeElements | null,
    priceId?: string,
    ev?: any
  ) => any;
  isPremiumUser: (user?: User) => boolean;
  isBaseUser: (user?: User) => boolean;
  getPaymentStrategiesWithoutBase: (
    paymentStrategies: PaymentStrategies
  ) => [PaymentStrategiesIds, RecurringPayments | OneTimePayments][];
  getUserPlanFromPriceId: (
    settingsPaymentStrategies: PaymentStrategies,
    userPriceId?: User['monetization']['priceId']
  ) => PaymentStrategiesIds | '';
  isBusinessUser: (user?: User) => boolean;
  isPersonalUser: (user?: User) => boolean;
  formatRecurringInterval: (
    interval: Interval,
    intervalCount: number
  ) => string;
  useSubscriptionManager: () => SWRResponse<
    SubscriptionInfo,
    AxiosError<unknown, any>
  >;
};

export const createPaymentService = ({
  post,
  get,
  put,
  deleteRequest,
}: HttpService): PaymentService => {
  const createPortalSession = async (
    cancellationCode: string,
    customerId: string
  ): Promise<PortalSession> =>
    post('/v1/web/stripe/portals-migration', {
      cancellationCode,
      customerId,
    });

  const getStripeClientIntentsData = async (): Promise<Intents> =>
    post('/v1/web/stripe/intents', {
      stripe_account: STRIPE_ACCOUNT_ID,
    });

  const createStripePaymentIntents = async ({
    priceId,
    taskId,
    exports,
  }: {
    priceId: string;
    taskId?: string;
    exports?: number;
  }): Promise<PaymentIntents> =>
    post('/v1/web/stripe/payment_intents', {
      price_id: priceId,
      client_task_id: taskId,
      unlocked_exports: exports || 1,
    });

  const confirmStripePaymentIntent = async ({
    paymentIntentId,
  }: {
    paymentIntentId: string;
  }): Promise<PaymentIntents> =>
    put(`/v1/web/stripe/payment_intents/${paymentIntentId}`, {});

  const createStripeSubscription = async (
    paymentMethodId: string,
    priceId: string,
    isFreeTrialEnabled: boolean,
    withIntroPrice: boolean,
    unlockedExports?: number | null
  ): Promise<Subscriptions> =>
    post('/v1/web/stripe/subscriptions', {
      paymentMethodId,
      priceId,
      trial: isFreeTrialEnabled,
      unlockedExports,
      introPriceId: withIntroPrice
        ? process.env.NEXT_PUBLIC_STRIPE_INTRO_PRICE_ID
        : null,
    });

  const createPaypalSubscription = async (
    subscriptionId: string,
    unlockedExports?: number | null
  ) =>
    post('/v1/web/paypal/subscriptions', {
      subscriptionId,
      unlockedExports,
    });

  const createPaypalCharge = async ({
    chargeId,
    amountCents,
    currency,
    taskId,
    paypalEmailAddress,
    exports,
  }: PaypalCharge) =>
    post('/v1/web/paypal/charges', {
      chargeId,
      amountCents,
      currency,
      clientTaskId: taskId,
      paypalEmailAddress,
      unlockedExports: exports,
    });

  const getSubscription = async (
    subscriptionProvider: string
  ): Promise<SubscriptionInfo> =>
    get(`/v1/web/${subscriptionProvider}/subscriptions`);

  const previewSubscriptionUpgrade = async (
    subscriptionProvider: string,
    newPriceId?: string
  ): Promise<UpgradePreviewInfo> => {
    let requestPath = `/v1/web/${subscriptionProvider}/subscriptions/upgrade/preview`;
    if (subscriptionProvider === 'stripe') {
      requestPath = `${requestPath}?new_price_id=${newPriceId}`;
    }

    return get(requestPath);
  };

  const upgradeStripeSubscription = async (newPriceId: string) => {
    await put('/v1/web/stripe/subscriptions/upgrade', {
      newPriceId,
      introPriceId: process.env.NEXT_PUBLIC_STRIPE_INTRO_PRICE_ID,
    });
  };

  const upgradePaypalSubscription = async (newSubscriptionId: string) => {
    await put('/v1/web/paypal/subscriptions/upgrade', {
      newSubscriptionId,
    });
  };

  const cancelSubscription = async (subscriptionProvider: string) =>
    deleteRequest(`/v1/web/${subscriptionProvider}/subscriptions`);

  const cancelSubscriptionFromEmail = async (
    subscriptionProvider: string,
    cancellationCode: string
  ) =>
    deleteRequest(`/v1/web/${subscriptionProvider}/subscriptions/email`, {
      cancellationCode,
    });

  const isPaypal = (providerRef: string): boolean =>
    providerRef === PaymentProvider.PayPal;

  const trackPayment = ({
    track,
    status,
    paymentPlatform,
    priceId,
    freeTrialEnabled,
    emailReminderEnabled,
    paymentStrategy,
  }: {
    track: (
      category: string,
      action: string,
      additionalParams?: Record<string, any> | undefined,
      label?: string | undefined
    ) => void;
    status: string;
    paymentPlatform?: PaymentPlatform;
    priceId?: string;
    freeTrialEnabled?: boolean;
    emailReminderEnabled?: boolean;
    paymentStrategy: 'subscription' | 'oneTime';
  }) => {
    track(
      'payment',
      status,
      {
        paymentPlatform,
        priceId,
        freeTrialEnabled,
        emailReminderEnabled,
        paymentStrategy,
      },
      paymentPlatform
    );
  };

  const catchStripePriceError = (
    stripe?: Stripe | null,
    elements?: StripeElements | null,
    priceId?: string,
    ev?: any
  ) => {
    if (!stripe || !elements || !priceId) {
      Sentry.captureException(
        new Error('Unmet conditions. Wait a few seconds and try again.')
      );
      if (ev) {
        return ev.complete('fail');
      }
    }
  };

  const isPremiumUser = (user?: User): boolean =>
    !!user &&
    (user.monetization?.isSubscribed || user.giftCode?.isActive || user.isFree);

  const isBaseUser = (user?: User): boolean =>
    !user ||
    (!user.monetization?.isSubscribed &&
      !user.giftCode?.isActive &&
      !user.isFree);

  // TODO: understand why BASE/weekly has the same priceId of BUSINESS/weekly
  const getPaymentStrategiesWithoutBase = (
    paymentStrategies: PaymentStrategies
  ) => {
    const entries = Object.entries(paymentStrategies) as [
      PaymentStrategiesIds,
      RecurringPayments | OneTimePayments
    ][];
    return entries.filter(
      (paymentStrategy) => paymentStrategy[0] !== PaymentStrategiesIds.Base
    );
  };

  const getUserPlanFromPriceId = (
    settingsPaymentStrategies: PaymentStrategies,
    userPriceId?: User['monetization']['priceId']
  ): PaymentStrategiesIds | '' => {
    const paymentStrategies = getPaymentStrategiesWithoutBase(
      settingsPaymentStrategies
    );

    return paymentStrategies.reduce((acc, strategy) => {
      const matchPrice = Object.values(strategy[1]).find(
        (period: { price: string; deprecatedPrices?: string[] }) =>
          period.price === userPriceId ||
          (period.deprecatedPrices || []).includes(userPriceId || '')
      );

      return matchPrice ? strategy[0] : acc;
    }, '');
  };

  const isBusinessUser = (user?: User): boolean =>
    !!user &&
    (user.paymentStrategy === PaymentStrategiesIds.Business ||
      user.giftCode?.isActive ||
      user.isFree ||
      user.monetization?.subscriptionProvider === 'mobile');

  const isPersonalUser = (user?: User): boolean =>
    user?.paymentStrategy === PaymentStrategiesIds.Personal;

  return {
    createPortalSession,
    isPersonalUser,
    isBusinessUser,
    getUserPlanFromPriceId,
    getPaymentStrategiesWithoutBase,
    isBaseUser,
    catchStripePriceError,
    isPremiumUser,
    trackPayment,
    isPaypal,
    cancelSubscription,
    upgradePaypalSubscription,
    cancelSubscriptionFromEmail,
    upgradeStripeSubscription,
    previewSubscriptionUpgrade,
    getSubscription,
    createPaypalCharge,
    createPaypalSubscription,
    createStripeSubscription,
    createStripePaymentIntents,
    confirmStripePaymentIntent,
    getStripeClientIntentsData,
    formatRecurringInterval,
    useSubscriptionManager,
  };
};

export default serviceSingletonGetter(
  Symbol('paymentService'),
  createPaymentService
);
